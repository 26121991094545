
    import FancyImage from '@/app/components/FancyImage';
    import Masonry from 'masonry-layout';

    import { gsap } from '@/vendor';
    import { makeFancyImage } from '@/vendor';

    export default {
        components: {
            'fancy-img': FancyImage
        },

        mounted()
        {
            gsap.set( '.lightning .specular', { backgroundImage: 'linear-gradient(black, black 45%, #faf9f9 50%, black 55%, black)' });
            this.lightningTl = gsap.timeline({ paused: true });

            this.lightningTl.to( '.lightning .specular', { backgroundImage: 'linear-gradient(black, #a5a5a5 3%, #faf9f9 50%, black 98%, black)', duration: 0.1, repeat: 2 }, 0 );
            this.lightningTl.to( '.lightning .specular', { backgroundImage: 'linear-gradient(black, black 3%, #000000 50%, black 98%, black)', duration: 2 }, 1 );

            this.lightningTl.to( '.lightning .specular', { backgroundImage: 'linear-gradient(black, black 45%, #faf9f9 50%, black 55%, black)', duration: 0.5 }, 4 );

            // ----

            this.msnry = new Masonry( this.$refs.gallery, {
                itemSelector: '.photoframe',
                columnWidth: 20
            });

            this.masonInterval = setInterval( () =>
            {
                this.msnry.layout();
            }, 100 );

            setTimeout( () =>
            {
                clearInterval( this.masonInterval );

                this.makeScrollTrigger();
            }, 2000 );

            makeFancyImage( '.js-test', ['125deg', '#f09 30%', '#fc8b00', '#ff0', '#00ff8a', '#00cfff', '#cc4cfa 70%'], '/static/img/shader/hologram.jpg', '/static/img/shader/hologram.png' );

            //add custom style (eigenlijk in css file)
            let css = `.js-test .mask{
                background-size: 40% auto;
                background-repeat: no-repeat;
                background-position: center center;
            }`;

            let head = document.head;
            let style = document.createElement( 'style' );
            head.appendChild( style );

            style.type = 'text/css';
            style.appendChild( document.createTextNode( css ) );
        },

        methods: {
            makeScrollTrigger()
            {
                this.scrollT = gsap.to( '.lightning', {
                    scrollTrigger: {
                        markers: true,
                        trigger: '.lightning',
                        start: 'top 14%',
                        onToggle: self => {
                            if( self.isActive )
                            {
                                this.lightningTl.play(0);
                            }
                        },
                    }
                });
            }
        }
    };
