
const makeFancyImage = ( selector, colorArr, imgSrc, lightmapSrc ) =>
{
    if( !lightmapSrc )
    {
        lightmapSrc = imgSrc.replace( /^(.+)(\.jpg|\.png)$/, '$1-lightmap$2' );
    }

    // construct dom
    let wrapper = document.querySelector( selector );

    let origImg = document.createElement( 'img' );
    origImg.src = imgSrc;

    let specularLayer = document.createElement( 'div' );
    specularLayer.classList.add( 'shader-layer', 'specular' );

    let maskLayer = document.createElement( 'div' );
    maskLayer.classList.add( 'shader-layer', 'mask' );

    //parse default styles
    const css = `//style for mix-blend-modes
        ${selector}{
            position: relative;
            isolation: isolate;
            overflow: hidden;
        }

        ${selector} > img{
            width: auto;
            max-width: 100%;
        }

        ${selector} .shader-layer{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            background-size: 100% auto;
        }

        ${selector} .specular{
            mix-blend-mode: color-dodge;
            background-image: linear-gradient(${colorArr.join(',')});
            background-attachment: fixed;
        }

        ${selector} .mask{
            mix-blend-mode: multiply;
            background-image: url(${lightmapSrc});
        }
    `;

    let head = document.head;
    let style = document.createElement( 'style' );
    head.appendChild( style );

    style.type = 'text/css';
    style.appendChild( document.createTextNode( css ) );

    // add elements to dom
    wrapper.appendChild( origImg );
    wrapper.appendChild( specularLayer );
    specularLayer.appendChild( maskLayer );
}

export { makeFancyImage };
