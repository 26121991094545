import { gsap } from 'gsap';
import { makeFancyImage } from './mrynk/MixBlendMode';

import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );

export {
    gsap,
    makeFancyImage
};
