import { createRouter, createWebHistory } from 'vue-router';

import Overview from '@/app/pages/Overview';

// import AuroraBorealis from '@/app/showcases/AuroraBorealis';
// import Rousseau from '@/app/showcases/Rousseau';
import Shader from '@/app/showcases/Shader';
import Vans from '@/app/showcases/Vans';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: Shader
        },

        // {
        //     path: '/aurora-borealis',
        //     component: AuroraBorealis
        // },

        // {
        //     path: '/rousseau',
        //     component: Rousseau
        // },

        {
            path: '/shader',
            component: Shader
        },

        {
            path: '/vans',
            component: Vans
        }
    ]
});

router.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default router;
