import { createStore } from 'vuex';

export default createStore({

    modules: {
    },

    state: {
        showcases: [
            {
                name: 'Aurora borealis',
                route: 'aurora-borealis'
            },
            {
                name: 'Rousseau',
                route: 'rousseau'
            },
            {
                name: 'Shader demo',
                route: 'shader'
            },
        ]
    },

    getters: {
        showcases: state => state.showcases
    },

    mutations: {

    },

    actions: {

    }
});
