
    export default {
        props: {
            src: '',
            colors: {
                type: Array,
                default: []
            }
        },

        computed: {
            srcLightmap()
            {
                let map = this.src.replaceAll( '.jpg', '-lightmap.jpg' );
                return 'url(' + map + ')';
            },

            gradient()
            {
                let gradient = this.colors.join( ',' );
                return 'linear-gradient(' + gradient + ')';
            }
        }
    };
